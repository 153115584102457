<template>
    <v-card
        elevation="1"
        outlined
        min-height="200"
    >
        <v-card-title><v-icon :color="parameters.primaryColor" class="pr-2">mdi-lock</v-icon>Dados de acesso</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="signUp.account.cf1senha"
                    :rules="passwordRules"
                    label="Senha"
                    type="password"
                    required
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.confirmPassword"
                    :rules="passwordRules"
                    label="Confirme a senha"
                    type="password"
                    required
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>
                <p class="subtitle-2 font-weight-bold text-left ma-0">Para criar sua senha, lembre-se que:</p>
                <ul>
                    <li class="caption text-left">deve possuir no mínimo 8 dígitos</li>
                    <li class="caption text-left">deve possuir no mínimo 1 número</li>
                    <li class="caption text-left">deve possuir no mínimo 1 letra maiúscula</li>
                    <li class="caption text-left">deve possuir no mínimo 1 letra minúscula</li>
                </ul>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        valid: true,
        passwordRules: [
            (v) => !!v || "A senha é obrigatória",
            (v) => (v && v.length >= 8) || "No mínimo 8 dígitos",
            (v) => (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v)) || "Senha não cumpre os requisitos de segurança", //(?=.*[!@#$%^&*])
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
    },
    methods: {
        validateForm(){
            return this.$refs.form.validate() &&
                   this.signUp.account.cf1senha == this.signUp.account.confirmPassword
        }
    },
};
</script>

<style>
</style>