<template>
    <v-card elevation="1" outlined :min-height="$mq != 'lg' ? '' : '544'">
        <v-card-title
            ><v-icon :color="parameters.primaryColor" class="pr-2"
                >mdi-map-marker</v-icon
            >Endereço</v-card-title
        >
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="signUp.account.cf1cep"
                    :rules="cepRules"
                    label="CEP"
                    required
                    dense
                    flat
                    placeholder="Ex.: 95180-000"
                    outlined
                    :color="parameters.primaryColor"
                    v-mask="'#####-###'"
                    @blur="onFocusOut"
                    append-icon="mdi-magnify"
                    @click:append="onFocusOut"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1ender1"
                    :rules="addressRules"
                    label="Endereço"
                    type="text"
                    required
                    placeholder="Ex.: Rua Pedro Álvares Cabral"
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>
                
                <p v-if="signUp.account.cf3nome && signUp.account.cf3estado" :style="{color: parameters.primaryColor}" class="body-1 text-left">
                    <v-icon dark :color="parameters.primaryColor" class="pr-2">mdi-domain</v-icon>
                    {{ signUp.account.cf3nome }} - {{ signUp.account.cf3estado }}
                </p>

                <v-text-field
                    v-model="signUp.account.cf1numero"
                    :rules="numberRules"
                    label="Número"
                    type="text"
                    required
                    dense
                    flat
                    placeholder="Ex.: 123 ou S/N"
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1bairro"
                    :rules="districtRules"
                    label="Bairro"
                    type="text"
                    placeholder="Ex.: Centro"
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1compl"
                    label="Complemento"
                    type="text"
                    required
                    dense
                    flat
                    placeholder="Ex.: Casa/ Apto / próximo à"
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                
                <v-list
                    v-if="signUp.account.shippingAddress && 
                          (Object.keys(signUp.account.shippingAddress).length > 0 &&
                           signUp.account.shippingAddress.constructor === Object)"
                    tile
                    dense
                >
                    <v-subheader class="subtitle-2 font-weight-bold">Endereço de entrega</v-subheader>
                    <v-list-item three-line dense>
                        <v-list-item-avatar>
                            <v-icon color="primary" large>mdi-map-marker-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="text-left">{{ `${signUp.account.shippingAddress.cf1ender1}, ${signUp.account.shippingAddress.cf1numero}`}}</v-list-item-title>
                            <v-list-item-title class="text-left">{{ signUp.account.shippingAddress.cf1bairro }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left">{{ signUp.account.shippingAddress.cf1cep | cep }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="text-left">{{ `${signUp.account.shippingAddress.cf3nome}/${signUp.account.shippingAddress.cf3estado}` }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon color="primary" small @click="openDialog">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-btn
                    v-else
                    class="ma-1 caption text-none"
                    :color="parameters.secondaryColor"
                    plain
                    @click="openDialog"
                    dark
                    left
                >
                    <v-icon class="pr-2">mdi-truck-fast</v-icon>
                    Cadastrar um Endereço de entrega
                </v-btn>
                <DialogShippingAddress ref="dialogShippingAddress"/>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
const DialogShippingAddress = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/signUp/DialogShippingAddress.vue"
    );

export default {
    data: () => ({
        valid: true,
        cepRules: [
            (v) => !!v || "CEP é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 8) || "CEP inválido",
        ],
        addressRules: [
            (v) => !!v || "Endereço é obrigatório",
        ],
        numberRules: [
            (v) => !!v || "Número é obrigatório",
        ],
        districtRules: [
            (v) => !!v || "Bairro é obrigatório",
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
    },
    components:{
        DialogShippingAddress
    },
    methods: {
        validateForm() {
            return this.$refs.form.validate();
        },
        onFocusOut(){
            this.submitZipCode(this.signUp.account.cf1cep);
        },
        submitZipCode(zipCode){
            var str = new String(zipCode);
            str = str.trim();
            str = str.replace(/[^\d]/g, "");
            
            if (str.length != 8) {
                return;
            }
            this.$store.dispatch("signUp/getCep", str)
            .then(addressModel =>{
                if (addressModel.erro) {
                    this.signUp.account.cf1ender1 = ""
                    this.signUp.account.cf1bairro = ""
                    this.signUp.account.cf3nome = ""
                    this.signUp.account.cf3estado = ""
                    return;
                }
                this.signUp.account.cf1cep = addressModel.cep
                this.signUp.account.cf1ender1 = addressModel.logradouro
                this.signUp.account.cf1bairro = addressModel.bairro
                this.signUp.account.cf3nome = addressModel.localidade
                this.signUp.account.cf3estado = addressModel.uf
            })
        },
        openDialog(){
            this.$refs.dialogShippingAddress.openDialog()
        },
    },
};
</script>

<style>
</style>