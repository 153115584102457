<template>
    <v-card elevation="1" outlined min-height="302">
        <v-card-title
            ><v-icon :color="parameters.primaryColor" class="pr-2"
                >mdi-account</v-icon
            >Dados da empresa</v-card-title
        >
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    v-model="signUp.account.cf1cliente"
                    :rules="cpfcnpjRules"
                    :label="parameters.store.b2bcpf ? 'CPF/CNPJ' : 'CNPJ'"
                    required
                    dense
                    placeholder="Ex.: 01.234.567/8901-23"
                    flat
                    outlined
                    :color="parameters.primaryColor"
                    v-mask="maskCustomer"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1nome"
                    :rules="nameRules"
                    label="Razão social"
                    type="text"
                    required
                    placeholder="Ex.: Maria Pereira Comercio de roupas"
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1fant"
                    :rules="nameFantRules"
                    label="Nome fantasia"
                    type="text"
                    required
                    placeholder="Ex.: Loja da Maria"
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>
                
                <v-text-field
                    v-model="signUp.account.cf1insest"
                    label="Inscrição estadual"
                    type="number"
                    placeholder="Ex.: 123/4567891"
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1confone"
                    :rules="cellPhoneRules"
                    label="Celular"
                    type="text"
                    required
                    placeholder="Ex.: (99) 99999-9999"
                    dense
                    flat
                    v-mask="maskConfone"
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1fone"
                    :rules="phoneRules"
                    label="Telefone"
                    type="text"
                    required
                    placeholder="Ex.: (99) 9999-9999"
                    dense
                    flat
                    outlined
                    v-mask="maskFone"
                    :color="parameters.primaryColor"
                ></v-text-field>

                <v-text-field
                    v-model="signUp.account.cf1email"
                    :rules="emailRules"
                    label="E-mail"
                    placeholder="Ex.: joao@email.com"
                    type="text"
                    required
                    dense
                    flat
                    outlined
                    :color="parameters.primaryColor"
                ></v-text-field>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
    data: () => ({
        valid: true,
        nameRules: [
            (v) => !!v || "Razão social é obrigatória",
            (v) => v && v.length > 3 || "Razão social inválida",
            /* (v) => (v && /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(v)) || "Razão social inválida", */
        ],
        nameFantRules: [
            (v) => !!v || "Nome fantasia é obrigatório",
            (v) => v && v.length > 3 || "Nome fantasia inválido",
            /* (v) => (v && /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i.test(v)) || "Nome fantásia inválido", */
        ],
        cellPhoneRules: [
            (v) => !!v || "Celular é obrigatório",
/*             (v) => (v && /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v.replace(/[^\d]/g, ""))) || "Celular inválido", */
        ],
        phoneRules: [
            /* (v) => !!v || "Telefone é obrigatório", */
            /* (v) => (v == "" || /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/.test(v.replace(/[^\d]/g, ""))) || "Telefone inválido", */
        ],
        emailRules: [
            (v) => !!v || "E-mail é obrigatório",
            (v) => (v && /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(v)) || "E-mail inválido",
        ],
    }),
    directives: { VueMaskDirective },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
        maskFone(){
            return this.signUp.account.cf1fone && this.signUp.account.cf1fone.replace(/[^\d]/g, "").length > 10 ? "(##) #####-####" : "(##) ####-####"
        },
        maskConfone(){
            return this.signUp.account.cf1confone && this.signUp.account.cf1confone.replace(/[^\d]/g, "").length > 10 ? "(##) #####-####" : "(##) ####-####"
        },
        maskCustomer(){
            if (!this.parameters.store.b2bcpf || (this.signUp.account.cf1cliente && this.signUp.account.cf1cliente.replace(/[^\d]/g, "").length > 11))
                return "##.###.###/####-##"
            return "###.###.###-##"
        },
        cpfcnpjRules(){
            return [
                (v) => !!v || (this.parameters.store.b2bcpf ? "CPF/CNPJ é obrigatório" : "CNPJ é obrigatório"),
                (v) => (v && v.replace(/[^\d]/g, "").length != 14) ? (this.parameters.store.b2bcpf ? ((v && v.replace(/[^\d]/g, "").length != 11) ? "CPF inválido" : true ): "CNPJ inválido") : true,
            ]
        }
    },
    methods: {
        validateForm(){
            return this.$refs.form.validate()
        }
    },
};
</script>

<style>
</style>