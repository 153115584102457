<template>
    <v-main style="min-height: 80% !important;" >
        <v-container class="pb-12">
            <v-row class="pt-5">
                <v-col cols="12">
                    <h2 :style="{ color: parameters.primaryColor }">
                        Insira seus dados!
                    </h2>
                </v-col>
                <v-col cols="12" v-if="signUp.isError" >
                    <FormAlert :messageError="signUp.messageError"/>
                </v-col>
                <v-col cols="12">
                    <SignUpLogin ref="login"/>
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12 : 6">
                    <SignUpAccountData ref="accountData"/>
                </v-col>
                <v-col :cols="$mq != 'lg' ? 12 : 6">
                    <SignUpAddressData ref="addressData"/>
                </v-col>
                <v-col cols="12">
                    <div class="d-flex justify-center">
                        <v-btn text @click="$router.push('/login')" class="mr-1">Cancelar</v-btn>
                        <v-btn dark :color="parameters.secondaryColor" @click="sendData" class="ml-1">Enviar dados</v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
const SignUpLogin = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/signUp/SignUpLogin.vue"
    );
const SignUpAccountData = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/signUp/SignUpAccountData.vue"
    );
const SignUpAddressData = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/signUp/SignUpAddressData.vue"
    );
const FormAlert = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/form/FormAlert.vue"
    );
    
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
    },
    components:{
        SignUpLogin,
        SignUpAccountData,
        SignUpAddressData,
        FormAlert,
    },
    methods:{
        sendData(){
            if (this.$refs["login"].validateForm() &&
                this.$refs["accountData"].validateForm() &&
                this.$refs["addressData"].validateForm()
            ){
                this.$store.dispatch("signUp/signUp")
            }else{
                if (this.signUp.account.cf1senha != this.signUp.account.confirmPassword) {
                    this.$store.dispatch("parameters/showSnackBar", {
                        text: "A senha e a confirmação precisam ser iguais",
                        show: true,
                        timeout: 6000
                    })
                }
            }
        }
    }
};
</script>

<style>
</style>