<template>
    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="show"
        persistent
    >
        <v-card elevation="1" outlined min-height="470">
            <v-card-title
                ><v-icon :color="parameters.primaryColor" class="pr-2"
                    >mdi-truck-fast</v-icon
                >Endereço de Entrega</v-card-title
            >
            <v-card-text>
                <v-form ref="formaddress">
                    <v-text-field
                        v-model="shippingAddress.cf1cep"
                        type="text"
                        :rules="cepRules"
                        label="CEP"
                        required
                        placeholder="Ex.: 95180-000"
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                        v-mask="'#####-###'"
                        @blur="onFocusOut"
                        append-icon="mdi-magnify"
                        @click:append="onFocusOut"
                    ></v-text-field>

                    <v-text-field
                        v-model="shippingAddress.cf1ender1"
                        :rules="addressRules"
                        label="Endereço"
                        type="text"
                        required
                        dense
                        placeholder="Ex.: Rua Pedro Álvares Cabral"
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>
                    
                    <p v-if="shippingAddress.cf3nome && shippingAddress.cf3estado" :style="{color: parameters.primaryColor}" class="body-1 text-left">
                        <v-icon dark :color="parameters.primaryColor" class="pr-2">mdi-domain</v-icon>
                        {{ shippingAddress.cf3nome }} - {{ shippingAddress.cf3estado }}
                    </p>

                    <v-text-field
                        v-model="shippingAddress.cf1numero"
                        :rules="numberRules"
                        label="Número"
                        type="text"
                        placeholder="Ex.: 123 ou S/N"
                        required
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>

                    <v-text-field
                        v-model="shippingAddress.cf1bairro"
                        :rules="districtRules"
                        label="Bairro"
                        type="text"
                        dense
                        placeholder="Ex.: Centro"
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>

                    <v-text-field
                        v-model="shippingAddress.cf1compl"
                        label="Complemento"
                        type="text"
                        required
                        placeholder="Ex.: Casa/ Apto / próximo à"
                        dense
                        flat
                        outlined
                        :color="parameters.primaryColor"
                    ></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="close">Fechar</v-btn>
                <v-spacer></v-spacer>
                <v-btn dark :color="parameters.secondaryColor" @click="save">Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        show: false,
        shippingAddress: {
            "cf1cep" : "",
            "cf1ender1" : "",
            "cf1bairro" : "",
            "cf3nome" : "",
            "cf3estado" : "",
            "cf1numero" : "",
            "cf1compl" : "",
        },
        cepRules: [
            (v) => !!v || "CEP é obrigatório",
            (v) => (v && v.replace(/[^\d]/g, "").length == 8) || "CEP inválido",
        ],
        addressRules: [
            (v) => !!v || "Endereço é obrigatório",
        ],
        numberRules: [
            (v) => !!v || "Número é obrigatório",
        ],
        districtRules: [
            (v) => !!v || "Bairro é obrigatório",
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        signUp: {
            get() {
                return this.$store.state.signUp;
            },
        },
    },
    methods: {
        openDialog(){
            this.show = true
            if(this.signUp.account.shippingAddress == null){
                this.shippingAddress = {
                    "cf1cep" : "",
                    "cf1ender1" : "",
                    "cf1bairro" : "",
                    "cf3nome" : "",
                    "cf3estado" : "",
                    "cf1numero" : "",
                    "cf1compl" : "",
                }
                return 
            }
            this.shippingAddress = {...this.signUp.account.shippingAddress}
        },
        save(){
            if (this.$refs.formaddress.validate()) {
                this.show = false
                this.signUp.account.shippingAddress = this.shippingAddress
            }
        },
        onFocusOut(){
            this.submitZipCode(this.shippingAddress.cf1cep);
        },
        submitZipCode(zipCode){
            var str = new String(zipCode);
            str = str.trim();
            str = str.replace(/[^\d]/g, "");
            
            if (str.length != 8) {
                return;
            }
            this.$store.dispatch("signUp/getCep", str)
            .then(addressModel =>{
                if (addressModel.erro == true) {
                    this.shippingAddress.cf1ender1 = ""
                    this.shippingAddress.cf1bairro = ""
                    this.shippingAddress.cf3nome = ""
                    this.shippingAddress.cf3estado = ""
                    return;
                }
                this.shippingAddress.cf1cep = addressModel.cep
                this.shippingAddress.cf1ender1 = addressModel.logradouro
                this.shippingAddress.cf1bairro = addressModel.bairro
                this.shippingAddress.cf3nome = addressModel.localidade
                this.shippingAddress.cf3estado = addressModel.uf
            })
        },
        close(){
            this.show = false
            this.shippingAddress = {
                "cf1cep" : "",
                "cf1ender1" : "",
                "cf1bairro" : "",
                "cf3nome" : "",
                "cf3estado" : "",
                "cf1numero" : "",
                "cf1compl" : "",
            }
        }
    },
};
</script>

<style>
</style>